<template>
  <div class="static-page">

    <section class="static-page__hero">
      <div class="static-page__hero-backdrop">
        <img class="static-page__hero-image" src="assets/images/booking/statics/about-hero.jpg" alt="viaggia con noi hero" />
      </div>
    </section>

    <section class="section">
      <div class="static-page__isvadobay">
        <b-container>
          <p class="mb-5" v-html="'Il servizio VadoBay è offerto da <strong>Salabam Srl</strong>, società specializzata in <strong>soluzioni corporate di booking online</strong> per il loyalty, il marketing e il welfare aziendale.'" />
          <h2 class="vb-heading h3 mb-5" v-html="'VadoBay è'" />
          <b-row>
            <b-col md="6" lg="3">
              <b-card bg-variant="transparent" no-body class="border-0 align-items-center text-center">
                <b-card-img src="assets/images/common/brands/vacanze-by.svg" alt="vacanze by" />
                <b-card-body>
                  <b-card-title class="h5" v-html="'vacanze by'" />
                  <b-card-text v-html="'prenotazione di pacchetti viaggio'" />
                </b-card-body>
              </b-card>
            </b-col>
            <b-col md="6" lg="3">
              <b-card bg-variant="transparent" no-body class="border-0 align-items-center text-center">
                <b-card-img src="assets/images/common/brands/live-booking.svg" alt="live booking" />
                <b-card-body>
                  <b-card-title class="h5" v-html="'live booking'" />
                  <b-card-text v-html="'prenotazione di soggiorni'" />
                </b-card-body>
              </b-card>
            </b-col>
            <b-col md="6" lg="3">
              <b-card bg-variant="transparent" no-body class="border-0 align-items-center text-center">
                <b-card-img src="assets/images/common/brands/fly-by.svg" alt="fly by" />
                <b-card-body>
                  <b-card-title class="h5" v-html="'fly by'" />
                  <b-card-text v-html="'prenotazione voli'" />
                </b-card-body>
              </b-card>
            </b-col>
            <b-col md="6" lg="3">
              <b-card bg-variant="transparent" no-body class="border-0 align-items-center text-center">
                <b-card-img src="assets/images/common/brands/booking.svg" alt="booking" />
                <b-card-body>
                  <b-card-title class="h5" v-html="'Booking.com'" />
                  <b-card-text v-html="'prenotazione soggiorni'" />
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>

          <p class="mt-5" v-html="'Vadobay è stato scelto dalle maggiori <strong>piattaforme di welfare aziendale</strong> italiane grazie alla sua affidabilità e semplicità d’uso e grazie ad un <strong>servizio di assistenza clienti unico nel panorama del travel booking nazionale</strong> e non solo.'" />
          <div class="static-page__quote">
            <vb-icon :name="'quote'" :color="'f8af3c'" />
            <h3 class="static-page__quote-title vb-heading h1" v-html="'Pagamenti con addebito diretto sul conto welfare, senza burocrazia e voucher'" />
            <vb-icon :name="'quote'" :color="'f8af3c'" />
          </div>
          <p v-html="'Gli utenti di welfare aziendale possono effettuare prenotazioni in tempo reale senza la generazione di alcun voucher e senza la gestione burocratica dei rimborsi.'" />
        </b-container>
      </div>
    </section>

    <section class="section">
      <b-container>
        <h2 class="vb-heading h3 mb-4" v-html="config.site.brand + ' ' + config.site.payoff" />
        <p class="mb-5" v-html="config.site.brand + ' ' + config.site.payoff + ' è il servizio di Salabam srl per prenotare disponibilità e offerte Booking.com'" />

        <b-row class="pt-4">
          <b-col class="mb-2" md="12" lg="4">
            <div class="static-page__amenities border-0" no-body>
              <vb-icon :name="'call_center'" color="fff" :size=60 />
              <div class="static-page__amenities-body">
                <h4 class="static-page__amenities-title h5" v-html="'Assistenza dedicata'" />
                <div class="static-page__amenities-text" v-html="'I tuoi agenti di viaggio di fiducia facili da raggiungere in tutte le fasi della prenotazione'" />
              </div>
            </div>
          </b-col>
          <b-col class="mb-2" md="12" lg="4">
            <div class="static-page__amenities border-0" no-body>
              <vb-icon :name="'technology'" color="fff" :size=60 />
              <div class="static-page__amenities-body">
                <h4 class="static-page__amenities-title h5" v-html="'Tecnologia'" />
                <div class="static-page__amenities-text" v-html="'Sviluppo tecnologico continuo per garantire un costante incremento della user experience'" />
              </div>
            </div>
          </b-col>
          <b-col class="mb-2" md="12" lg="4">
            <div class="static-page__amenities border-0" no-body>
              <vb-icon :name="'engine'" color="fff" :size=60 />
              <div class="static-page__amenities-body">
                <h4 class="static-page__amenities-title h5" v-html="'Integrazione'" />
                <div class="static-page__amenities-text" v-html="'Integrazione tecnologica con i maggiori player e fornitori di soluzioni di viaggio e con i maggiori tour operator'" />
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>

    <section class="section">
      <div class="static-page__form" id="contattaci">
        <b-container>
          <form-contatti
              :formTitle="title_request_form"
              :formSubTitle="subtitle_request_form"
              :label="'form-contatti-about'"
              :recipient="site.emailSupport"
          />
      </b-container>
      </div>
    </section>

  </div>
</template>


<script>
import Vue from "vue";
import FormContatti from '@components/blocks/form-contatti.vue'

export default {
  name: 'about',
  components:{
    'form-contatti': FormContatti,
  },
  props: {
  },
  created() {
  },
  data () {
    return {
      config: Vue.prototype.$config,
      site: Vue.prototype.$config.site,
      title_request_form: 'Hai bisogno di <span class=\'text-secondary\'>maggiori informazioni?</span> Contattaci!',
      subtitle_request_form: 'Compila il seguente form oppure scrivi una mail a <a href=\'mailto:' + Vue.prototype.$config.site.emailSupport + '\'>' + Vue.prototype.$config.site.emailSupport + '</a>',
    }
  },
  computed: {
  },
}
</script>


<style lang="scss" scoped>

  .static-page {

    &__hero {
      &-image {
        @include media-breakpoint-up(xl) {
          top: -35%;
        }
      }
    }
  }

</style>